<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Change Password
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div
          class="card p-60px"
          :class="[height_card < 550 ? 'pb-450px' : '']"
          ref="card"
        >
          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>
          <div class="row" v-if="loading == false">
            <div class="col-12 col-sm-12" v-if="formMessage !== ''">
              <div class="formMessage">
                <p>{{ formMessage }}</p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-xl-5">
              <div class="mt-4 settings-form">
                <div class="input-container">
                  <label for="">Current Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="passwd.current"
                  />
                </div>
                <div class="input-container">
                  <label for="">New Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="passwd.new"
                  />
                  <label for=""
                    >Min 8 characters, must contain at least 1 digit</label
                  >
                </div>
                <div class="input-container">
                  <label for="">Retype Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="passwd.re_new"
                  />
                </div>
                <button
                  class="button-expertel float-right"
                  @click="changePsw()"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_user"],
  data() {
    return {
      height_card: 0,
      passwd: {
        current: "",
        new: "",
        re_new: ""
      },
      loading: false,
      formMessage: ""
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.calculateHeight;
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    changePsw() {
      if (this.passwd.new !== this.passwd.re_new) {
        this.makeToast(
          "ERROR",
          "The new password don't match, try again",
          "danger"
        );
        return false;
      } else {
        const self = this;
        this.loading = true;
        this.axios({
          method: "post",
          url: `https://api.expertel.ca/api/v1/user/${self.id_user}/password`,
          responseType: "json",
          data: {
            currentPassword: self.passwd.current,
            newPassword: self.passwd.new
          },
          headers: {
            Authorization: "Bearer " + self.login_data.token
          }
        })
          .then(function(response) {
            if (response.data.success === false) {
              self.loading = false;
              self.formMessage = response.data.message;
            } else {
              self.loading = false;
              self.changeIDUser(self.login_data.user.id);
              self.formMessage = "Your password has been successfully changed.";
            }
          })
          .catch(error => (self.formMessage = error));
      }
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    changeIDUser(id) {
      this.$emit("changeIDUser", id);
    }
  }
};
</script>
